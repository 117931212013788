import { CpEnvironment } from '@environment/environment.interface';

/*
Service that contains the environment configuration for the application.
This is even loaded before angular in apps/portal/src/main.ts.
 */
export class CpEnvironmentService {
  private static environment: CpEnvironment;

  static setEnvironment(environment: CpEnvironment) {
    CpEnvironmentService.environment = CpEnvironmentService.environment ?? environment; // environment can be set only once
  }

  static getProperties(): CpEnvironment {
    return CpEnvironmentService.environment;
  }
}
