import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CpEnvironment, CpEnvironmentService, environment } from '@environment';

async function bootstrapApp(): Promise<void> {
  const cpEnvironment: CpEnvironment = await fetch(`/assets/environment/environment.json?v=${new Date().getTime()}`)
    .then((r: Response) => r.json())
    .then((r: unknown) => r as CpEnvironment);
  CpEnvironmentService.setEnvironment(cpEnvironment);

  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic().bootstrapModule((await import('./app/app.module')).AppModule);
}

bootstrapApp();
